<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
        <div class="va-card mb-4">
          <div class="dash_card_body  pad-lr-0 ov-h">
            <div class="header_section padd-30 pt-0">
              <div class="back-btn mt-0 mr-2">
                <router-link :to="{ name: 'staffs'}"><i class="fa fa-arrow-left" aria-hidden="true"></i></router-link>
              </div> <div class="w-100 t-wrap">
                <h4 class="menu_header">Create Staff</h4>
              </div>

              
            </div>
            <div class="create_items_section">
              <form @submit.prevent="createStaff">
                <div v-if="errors ">
                  <div class="alert alert-danger">
                    <ul>
                      <li v-for="(err_msg,errindex) in errorMsg" :key="errindex">{{err_msg}}</li>
                    </ul>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 bd-right pad-lr-0">
                    <div class="pl-5 pr-4 pb-4">
                      <div class="setting_content">
                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-4" for="name">Name:</label>
                            <div class="col-sm-8">
                              <div>
                                <input type="text" class="form-control" v-model="name" @blur="v$.name.$touch">
                              </div>
                              <div class="input_error" v-if="v$.name.$error">
                                <span v-if="v$.name.required.$invalid">Name is required</span>
                              </div>
  
                             
                            </div>
                          </div>
                        </div>
                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-4" for="email">Email:</label>
                            <div class="col-sm-8">
                              <input type="email" class="form-control" v-model="email" @blur="v$.email.$touch"/>
                              <div class="input_error" v-if="v$.email.$error">
                                <span v-if="v$.email.required.$invalid">Email is required</span>
                                <span v-if="v$.email.email.$invalid">Email is Invalid</span>
                              </div>
                            </div>
                            
                          </div>
                        </div>
                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-4" for="dm_consumer_secret">Phone:</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" v-model="phone" @blur="v$.phone.$touch"/>
                              <div class="input_error" v-if="v$.phone.$error">
                                <span v-if="v$.phone.required.$invalid">Phone is required</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-4" for="dm_consumer_secret">Role:</label>
                            <div class="col-sm-8">
                              <div>
                                <select class="form-control" v-model="role" @blur="v$.role.$touch">
                                  <option value="">-Select Role-</option>
                                  <option v-for="rol in roles" :key="rol.id" :value="rol.id">{{rol.name}}</option>
                                </select>
                              </div>
                              <div class="input_error" v-if="v$.role.$error">
                                <span v-if="v$.role.required.$invalid">Role is required</span>
                              </div>
                              
  
                            </div>
                          </div>
                        </div>
                        <div class="form-group setting_form_grp">
                          <div class="row">
                            <label class="control-label col-sm-4" for="dm_consumer_secret">Status:</label>
                            <div class="col-sm-8">
                              <select class="form-control" v-model="status">
                                <option value="1">Active</option>
                                <option value="0">InActive</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div class="form-group setting_form_grp" v-if="role == UserRoles.waiters">
                          <div class="row">
                            <label class="control-label col-sm-4" for="dm_consumer_secret">Employee Code:</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" v-model="employeeCode">
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <!--Right Col-->
                  <div class="col-md-6 pad-l-0">
                    <div class="pl-4 pr-5 pb-4">
                      <h2 class="section-title">Assign Stores:</h2>
                      <div class="form-group setting_form_grp" >
                        <div class="row">
                          <div class="col-ld-12 col-md-12 col-sm-12 mb-3" v-for="merchant in merchants" :key="merchant.id">
                            <div v-if="merchant.status ==1">
                              <input @click="checkAll(merchant.id)" :id="'check_all_'+merchant.id" type="checkbox" :disabled="merchant.status ==1 && role !=7 ? false: true"  class="mr-2" ><label>{{merchant.name}}</label>
                              <div class="ml-5" v-for="(store, sindex) in merchantStores[merchant.id]" :key="sindex">
                                <input :id="'store'+store.id" @click="limitStoreSelection(merchant.id,store.id)"  type="checkbox" class="mr-2" ><label>{{store.branch_name}}</label>
                              </div>
                            </div>
                            
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                
                

                </div>

                <div class="row bt-b">
                  <div class="col-md-12">
                    <div class="form-group ml-2r mt-3">
                      <button type="submit" class="btn btn-orange"><span v-if="spinner" v-html="searchspinner"></span> <span v-else>Save</span></button>
                    </div>
                  </div>
                </div>
              <!-- end row -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
// import Multiselect from '@vueform/multiselect';
import { required, email } from '@vuelidate/validators';
import { useToast } from "vue-toastification";
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
const toast = useToast();
export default {
    components: {
        // Multiselect,
    },
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            name: '',
            email: '',
            phone: '',
            errors: false,
            status: 1,
            options: [],
            spinner: false,
            vouchers: [],
            errorMsg:[],
            roles: [],
            role:'',
            merchants: [],
            merchantStores:[],
            stores:[],
            staffId: this.$route.params.id,
            searchspinner: "<i class='fa fa-spinner fa-spin '></i> Saving Changes...",
            employeeCode:''

        }
    },
    mounted: async function () {
        this.getRoles();
        this.getBranches();
        this.getMerchantById();
    },
    validations() {
        return {
            name: { required },
            phone: { required },
            email: { required , email },
            role: { required },

        }
    },
    methods: {
        async createStaff() {
            this.spinner = true;
            this.errors = false;

            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                this.spinner = false;
                return
            }
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.dashBaseUrl + "/create-staff";
            const baseurl = window.location.origin +"/";
            const payload = {
                'name': this.name,
                'email': this.email,
                'phone': this.phone,
                'role': this.role,
                'stores' : this.stores,
                'status': this.status,
                'base_url': baseurl,
                'merchant_id':this.merchantID,
                'employee_code': this.employeeCode
            }
            this.axios.post(url,payload,config).then((response)=>{
                if(response.status === 200){
                    this.$router.push({
                        name: 'staffs'
                    });
                    toast.success('staff created successfully');
                }
            }).catch((error)=>{
                if(error.response.status === 400){
                  this.errors = true;
                    this.errorMsg = error.response.data.errors
                }else if(error.response.status === 401){
                    this.$router.push({
                        name: 'login'
                    });
                }
            }).finally(()=> this.spinner = false);

        },
        async getMerchantById(){
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = `${this.dashBaseUrl}/related-merchants/${this.merchantID}?user_id=${this.user.id}`;
            this.axios.get(url,config).then((response)=>{
                if(response.status === 200){
                    this.merchants = response.data.data;
                    for(let x=0 ; x < this.merchants.length ; x++){
                      this.getBranchesByMerchantId(this.merchants[x].id)
                    }
                }
            }).catch((error)=>{
                console.log(error)
            });
        },
        limitStoreSelection(merchantId,storeId){
          if(this.role == 7 && document.getElementById(`store${storeId}`).checked){
            for(let s = 0; s < this.merchantStores[merchantId].length ; s ++){
              if(storeId !=this.merchantStores[merchantId][s].id){
                document.getElementById('store'+this.merchantStores[merchantId][s].id).disabled = true
              }
            }
          }else{
            for(let s = 0; s < this.merchantStores[merchantId].length ; s ++){
              if(storeId !=this.merchantStores[merchantId][s].id){
                document.getElementById('store'+this.merchantStores[merchantId][s].id).disabled = false
              }
            }

          }
        },
        checkAll(merchantId) {
          if(this.role === ''){
            toast.error("please select Role first")
            document.getElementById('check_all_'+merchantId).checked = false
            return
          }
          if(document.getElementById('check_all_'+merchantId).checked){
            for(let s = 0; s < this.merchantStores[merchantId].length ; s ++){
              document.getElementById('store'+this.merchantStores[merchantId][s].id).checked = true;
               if(this.stores.indexOf(this.merchantStores[merchantId][s].id) === -1){
                  this.stores.push(this.merchantStores[merchantId][s].id);
                }
            }
          }else{
            for(let s = 0; s < this.merchantStores[merchantId].length ; s ++){
              document.getElementById('store'+this.merchantStores[merchantId][s].id).checked = false;
              this.stores.pop(this.merchantStores[merchantId][s].id);
            }
          }

        },
        async getRoles(){
            // 
            const myArray = [1,4];
            const arrStr = myArray.join('&' + 'roles_ids[]=');
            const url = `${this.dashBaseUrl}/roles?roles_ids[]=${arrStr}`;
            this.axios.get(url).then((response) => {
                if(response.status === 200){
                this.roles = response.data.data;
                }
            }).catch((error) =>{
                console.log('roles error',error);
            })
        },
        async getBranchesByMerchantId(merchantId){
          const config = {
              headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
          };
          const url = this.dashBaseUrl + "/merchant-stores/" + merchantId;
          const response = await this.axios.get(url, config)
          this.merchantStores[merchantId] = response.data.data
        },
        async getBranches() {
            const url = `${this.baseUrl}/branch?merchant_id=${this.merchantID}`;
            this.axios.get(url, this.apiRequestConfig()).then((response) => {
                if (response.status === 200) {
                    const stores = response.data.data; 
                    for(let s = 0; s < stores.length; s++){
                        this.options.push({
                            "value": stores[s].id,
                            "name": stores[s].branch_name
                            })

                    }
                }
            }).catch((err) => {
                console.log('error', err);
            })

        },
    },
    mixins: [ReusableDataMixins]
}
</script>
